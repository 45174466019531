import React from 'react';
import styled from '@emotion/styled';
import FunctionsHeader from 'components/Organisms/PageHeader/FunctionsHeader';
import Pricing from 'components/Organisms/Pricing';
import { FluidObject } from 'gatsby-image';
import Navigation from 'components/Organisms/Navigation';
import WhyChooseVoda from 'components/Organisms/WhyChooseVoda';
import Footer from 'components/Organisms/Footer';
import PageMainDesc from 'components/Organisms/PageMainDesc';

const Component = styled.div`
  width: 100%;
  height: 100%;
`;

interface PricingTemplateProps {
  logoNerdFactoryImg: FluidObject;
  img: FluidObject;
  location: {
    pathname: string;
  };
}

const PricingTemplate = ({
  logoNerdFactoryImg,
  img,
  location,
}: PricingTemplateProps) => {
  return (
    <Component>
      <Navigation vodaLogoImg={logoNerdFactoryImg} />
      <FunctionsHeader title="A plan\nfor every product" img={img} />
      <PageMainDesc
        desc={{ main: 'VODA 합리적인 가격으로\\n경험해보세요', sub: '' }}
        location={location}
      />
      <Pricing />
      <WhyChooseVoda />
      <Footer />
    </Component>
  );
};

export default PricingTemplate;
