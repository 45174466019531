import React from 'react';
import styled from '@emotion/styled';
import { IconCheck } from 'components/Atom/Icon';
import { P3 } from 'components/Atom/Typo';

const Component = styled.div`
  display: flex;
  align-items: flex-start;

  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0px;
  }

  ${P3} {
    height: 20px;
    line-height: 1;
    color: ${(props) => props.theme.colors.grey600};
    margin-left: 8px;
  }

  svg {
    width: 16px;
    height: 12px;
    path {
      fill: ${(props) => props.theme.colors.grey800};
    }
  }
`;

const LabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

interface PriceCheckLabelProps {
  title: string;
  className?: string;
}

const PriceCheckLabel = ({ title, className }: PriceCheckLabelProps) => {
  return (
    <Component className={className}>
      <IconCheck />
      <LabelWrapper>
        {title.split('\\n').map((element) => {
          return <P3 key={element}>{element}</P3>;
        })}
      </LabelWrapper>
    </Component>
  );
};

export default PriceCheckLabel;
